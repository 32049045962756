import React from "react";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import MarkerClusterGroup from '../../Dependencies/react-leaflet-markercluster/react-leaflet-markercluster';

import "./css/CusMap.scss";

const CusMap = (props) => {

    
        
  return (
    <MapContainer
    className="card markercluster-map maps"
    center={[36.8, 8.54]}
    zoom={2}
    maxZoom={18}
    // style={{"height" : "auto", "width" : "-moz-available", "width" : "-webkit-fill-available", "width" : "400px" }}
    style={{"height" : "404px",  "width":"70%" }}
    >
    <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    />
    <MarkerClusterGroup>
      {/* switzerland */}
        <Marker position={[47.5, 8.54]} />
        <Marker position={[47.55, 8.64]} />
        <Marker position={[47.45, 8.74]} />
        <Marker position={[47.60, 8.84]} />
        <Marker position={[46.5, 6.6]} />
        <Marker position={[46.3, 8.8]} />
      {/* ghana */}
        <Marker position={[5.556, -0.197]} />
        <Marker position={[6.688, -1.624]} />
        <Marker position={[9.401, -0.839]} />
      {/* india */}
        <Marker position={[27.706, 78.08]} />
        <Marker position={[28.756, 77.18]} />
        <Marker position={[19.072, 72.01]} />



    </MarkerClusterGroup>

    </MapContainer>
  );
}

export default CusMap