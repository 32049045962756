import './Sidebar.scss'
import { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom'


// sidebar links
import { sidebarData } from '../../Data/Data'

// icons & assets
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import logo from '../../Assets/logo.png'

// azure imports
import { useMsal } from "@azure/msal-react";

// sidebar state context import
import { SidebarContext } from  '../../Context/GlobalContexts'

function handleLogout(instance) {
    instance.logoutPopup().catch(e => {
        console.error(e);
    });
}

const Sidebar = (props) => {
    
    const {selected, setSelected} = useContext(SidebarContext);
    useEffect(()=>setSelected(props.value ? props.value : 0 ))
    const { instance } = useMsal();

  return (
    <div className="sidebar">
        <div className="top">
            <Link to='/' style={{textDecoration:'none'}}>
                {/* <span className="logo">ARC</span> */}
                <img src={logo} alt="logo" className='logo' />
            </Link>
        </div>
        <hr />
        <div className="center">
            <ul>
                {
                    sidebarData.map((item, idx)=>{
                        return(
                            <Link to={item.to} key={idx} style={{textDecoration:'none'}}>
                                <li 
                                className={selected===idx ? 'active' : ''}
                                onClick={()=>setSelected(idx)} 
                                >
                                    {item.icon}
                                    <span className='text'>{item.text}</span>
                                </li>
                            </Link>
                        )
                    })
                }
            </ul>
        </div>

        {/* logout icon */}
        <div className="bottom">
            <div className="logout" onClick={() => handleLogout(instance)}>
                <ExitToAppIcon className='icon'/>
            </div>
        </div>
    </div>
  )
}

export default Sidebar



