//components

import NavWrapper from "../NavWrapper/NavWrapper";
import Streaming from './Streaming';

const StreamingWrapper = () => {
    return (
    <>
    <NavWrapper value={1}>
        <Streaming />
    </NavWrapper>
    </>
    )
}

export default StreamingWrapper