// core react imports 
import { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom'

// mui table imports
import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';


// custom imports
import './css/Table.scss'
import { TableContext } from '../../Context/TableContext';


// streaming table imports
import { ModalPlayer } from '../../Pages/Streaming/Streaming';



const columns = [
  // { id: 'id', label: 'Id', minWidth: 20 },
  { id: 'location_name', label: 'Location', minWidth: 100 },
  { 
    id: 'observed_at', 
    label: 'Date', 
    minWidth: 100, 
    format:(value) => new Date(value).toDateString()
  },
  { 
    id: 'object_type', 
    label: 'Type', 
    minWidth: 100,
    format:(value) => value === 'plastic_litter' ? 'plastic litter' : value
  },
  { 
    id: 'owner', 
    label: 'Owner', 
    minWidth: 100, 
    format:(value) => value !== null ? value : "Undisclosed"
  },
];


export default function StickyHeadTable({ payload }) {

  // state variables
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // Helper Functions
  const { setCurrRow }  = useContext(TableContext);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // set the current row when page loads
  useEffect(() => {
    if (payload.length > 0)
      setCurrRow(payload[0]['id'])
  }, [payload])


  return (
    <div className="table_wrapper">
      <Paper className='table' sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 250 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead className='tablehead'>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {payload.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, idx) => {
                  return (
                      <TableRow hover role="checkbox" 
                      tabIndex={-1} 
                      key={idx}
                      onClick={(e)=>{setCurrRow(row['id']); console.log(row['id'])}}
                      >
                        {columns.map((column, id) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format 
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                        
                      </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={payload.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

    </div>
  );
}


// --------------- Recorded Videos Table ----------------- //

const columnsRecordedVideos = [
  { id: 'name', label: 'Recorded Video', minWidth: 100 },
  { id: 'size', label: 'Size of Video File', minWidth: 100 },
  { 
    id: 'url', 
    label: 'Stream Video', 
    minWidth: 100, 
    format:(value) => 
      // set the url for download instead of streaming
      <>
        {/* <a href={value} download target="/"> play video </a> */}
        <ModalPlayer url={value} text={"stream"} />
      </>

  },

];


export function RecordedVideosTable({ payload }) {

  // state variables
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // Helper Functions
  const { setCurrRow }  = useContext(TableContext);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className='table' sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 250 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead className='tablehead'>
            <TableRow>
              {columnsRecordedVideos.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {payload.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, idx) => {
                return (
                    <TableRow hover role="checkbox" 
                    tabIndex={-1} 
                    key={idx}
                    onClick={(e)=>setCurrRow(row['id'])}
                    >
                      {columnsRecordedVideos.map((column, id) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format 
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                      
                    </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={payload.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

