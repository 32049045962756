import './css/Infographic.scss'

import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Legend, Tooltip } from 'chart.js';
// chart.js 3 has broken tree-shaking, so we have to fight against it
// see: https://www.chartjs.org/docs/3.7.0/getting-started/integration.html#bundlers-webpack-rollup-etc
Chart.register(ArcElement);
Chart.register(Legend);
Chart.register(Tooltip);

const Infographic = ({data}) => {
  return (
    <div className="card infographic">
        {
            data === null ? "Loading..." :
            <Doughnut
                className='piechart'
                data={{
                    labels: Array.from(data.perTypeCount).map(
                        ([type, _]) => type
                    ),
                    datasets : [{
                        label: 'Object types',
                        data: Array.from(data.perTypeCount).map(
                            ([_, count]) => count
                        ),
                        backgroundColor: (() => {
                            const a = Array.from(data.perTypeCount);
                            const l = a.length;
                            return a.map(
                                (_, i) => `hsl(${i * 360 / l},  60%, 55%)`
                            )
                        })
                    }],
                }}
            />				
        }
    </div>
  )
}

export default Infographic