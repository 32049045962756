import { useContext, useState, useEffect } from "react";
import { TableContext } from "../../Context/TableContext";


// assets
import Partner from '../../Assets/Partner.png'


// CSS
import './css/InfoCard.scss'

const InfoCard = ({cardData, payload}) => {

    const { currRow } = useContext(TableContext);
    const [data, setData] = useState(null);

  
	useEffect(() => {
		async function fetchData() {
			const result = await fetch(
                `/api/v0/observation/${currRow}/metadata`
			);
			setData(await result.json());
		}
		fetchData();
    console.log(data)
	}, [currRow]);



  

    return(
          data === null ? "Loading..." :
          <>
          <div className="card infocard">
              <h2 className='topic'>Extraction Details</h2>

              <div className="obs">
                {/* Image */}
                {/* TODO : which ID to use here */}
                <div className="obsimage">
                  <img src={`/api/v0/observation/${currRow}/image`} alt="observation"/>  
                </div>
    
                {/* observations */}
                <div className="obsbody">
                  <ul className="observations">
                    
                    {
                      cardData.map((item,idx) => {
                        return(
                          <li className="observation" key={idx}>
                            <i>
                              {item.icon}
                            </i>
                            <span>{item.tag==='stored_at'? new Date(data[item.tag]).toDateString() : data[item.tag]}</span>
                            {/* TODO: Format the object type item tag : plastic litter */}
                          </li>
                        )
                      })
                    }      
                  </ul>
                </div>
              </div>
          </div>
          
          {/* <div className="card recoverypartner">
              {/* Image */}
              {/* TODO : which ID to use here *//*}
              <img src={Partner} alt="observation" className="obsimage" />  
  
              {/* observations *//*}
              <div className="obsbody">
                <h2 className='topic'>Recovery Partner</h2>
                <ul className="observations">
                    <li className="observation">
                            <span>Autonomous River Cleanup</span> 
                    </li>
                    <li className="observation">
                            <span>Established 2019</span>
                    </li>
                    <li className="observation">
                            <span>Limmat River, Zurich</span>
                    </li>
                    <li className="observation">
                        <a href="https://riverclean.ethz.ch/">
                            <span>Learn More</span>
                        </a>
                    </li>

                </ul>
              </div>
          </div> */}
          
          </>
    )
  }

  export default InfoCard;