import { graphConfig } from "./authConfig";
import { AzureMediaServices } from '@azure/arm-mediaservices';
import { SecretClient } from "@azure/keyvault-secrets";



/**
 * Attaches a given access token to a Microsoft Graph API call. Returns information about the user
 */
export async function callMsGraph(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(graphConfig.graphMeEndpoint, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}

  

// call to the ams api to fetch user data
export async function callAMSapi(accessToken, expiresOn) {
    const subscriptionId = "1e81aa71-c148-44b7-8a3c-a8579736d3ef";
    const resourceGroup = "upcircle-pipeline";
    const accountName = "upcirclemediapipeline";

    const mySimpleCredential = 
    {
        getToken() {
            return {
              expiresOnTimestamp: expiresOn,
              token: accessToken
            };
          }
    };

    let mediaServicesClient =  new AzureMediaServices(mySimpleCredential, subscriptionId);
    // List Assets in Account
    console.log("Listing assets in account:")
    for await (const asset of mediaServicesClient.assets.list(resourceGroup, accountName, { top:1000 })){
        console.log(asset.name);
    }
    return accessToken;
}


// call to the sftp api to fetch recorded videos
export async function callSFTPapi(accessToken, facility_id, year , month, day) {
    

    const keyvault_url = "https://upcirclekeyvault.vault.azure.net/";
    
    // request options for POST request to sftp api
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
        body: JSON.stringify({
            token: accessToken,
            key_vault_url: keyvault_url,
            facility_id: facility_id,
            year: year,
            month: month,
            day: day
        })
    };

    // fetch the response from the sftp api
    // const response = await fetch('https://upcirclemediafunctions.azurewebsites.net/api/sharedLib/getsftp', requestOptions); // file share
    const response = await fetch('https://upcirclemediafunctions.azurewebsites.net/api/sharedLib/getblobfiles', requestOptions); // blob
    // const response = await fetch('http://localhost:7071/api/sharedLib/getsftp', requestOptions); // local
    const data = await response.json();
    // convert to array object
    // const videos = Object.entries(data);
    // return the array object
    // if data is valid, return the data
    if (data.files) {
        return data.files;
    }
    else {
        return [];
    }
}

