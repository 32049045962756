import { useState } from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  // Link
} from "react-router-dom";




// pages
import Insights from "./Pages/Insights/Insights";
import Login from "./Pages/Login/Login";
import StreamingWrapper from "./Pages/Streaming/StreamingWrapper";



// Global state contexts
import { SidebarContext } from './Context/GlobalContexts'

function App() {
  const [selected, setSelected] = useState(0);

  return (
    <Router>
      
        <SidebarContext.Provider value={{selected, setSelected}}>
      
        <div className="App">

          <Routes>
            <Route path="/">
              <Route index element={<Insights owner={null} />} />
              {/* <Route path="impact" element={<Impact />} /> */}
              <Route path="login" element={<Login />} />
              <Route path="streaming" element={ <StreamingWrapper /> } />
            </Route>
          </Routes>

        </div>
        
        </SidebarContext.Provider>
        
    </Router>
  );
}

export default App;
