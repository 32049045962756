import React, { useState } from "react";
import axios from "axios";

const FilteredList = ({ data, setData }) => {
  const [userInput, setUserInput] = useState("");

  const handleFilter = async () => {
    try {
      const response = await axios.post("http://localhost:3001/filterList", {
        userInput,
        data,
      });
      setData(response.data);
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <input
        type="text"
        value={userInput}
        onChange={(e) => setUserInput(e.target.value)}
      />
      <button onClick={handleFilter}>Filter list</button>
      <ul>
        {/* {Array.isArray(data) &&
          console.log(data)} */}
      </ul>
    </div>
  );
};

export default FilteredList;
