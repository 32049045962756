// Description: Sidebar for mobile devices

// React imports
import { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom'


// burger menu
import { slide as Menu } from 'react-burger-menu'


// sidebar data import
import { sidebarData } from '../../Data/Data' // sidebar links
import { SidebarContext } from  '../../Context/GlobalContexts' // sidebar state context import

// stylesheet and icons
import './SidebarMobile.scss'
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import logo from '../../Assets/logo.png'

// azure imports
import { useMsal } from "@azure/msal-react";


// logout function
function handleLogout(instance) {
    instance.logoutPopup().catch(e => {
        console.error(e);
    });
}

const Sidebar = (props) => {
    // For sidebar state management
    const {selected, setSelected} = useContext(SidebarContext); 
    useEffect(()=>setSelected(props.value ? props.value : 0 ));
    const { instance } = useMsal();
    

    // Render
    return (
        <>
        <div className="sidebar-mobile">
            <Menu pageWrapId={ "homeContainer" } outerContainerId={ "wrapper" } >
               {/* logo */}
                <div className="top menu-item" style="">
                        <Link to='/' style={{ textDecoration: 'none' }} >
                            <img src={logo} alt="logo" className='logo' />
                        </Link>
                    </div>
                    <hr />

                    {/* sidebar links */}
                    <div className="center menu-item">
                        <ul>
                            {
                                sidebarData.map((item, idx)=>{
                                    return(
                                        <Link to={item.to} key={idx} style={{textDecoration:'none'}}>
                                            <li 
                                            className={selected===idx ? 'active' : ''}
                                            onClick={()=>setSelected(idx)} 
                                            >
                                                {item.icon}
                                                <span className='text'>{item.text}</span>
                                            </li>
                                        </Link>
                                    )
                                })
                            }
                        </ul>
                    </div>

                    {/* logout icon */}
                    <div className="bottom menu-item--small">
                        <div className="logout" onClick={() => handleLogout(instance)}>
                            <ExitToAppIcon className='icon'/>
                        </div>
                    </div>
            </Menu>
        </div>
        </>
    )
}

export default Sidebar

