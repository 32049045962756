import {useEffect, useState } from 'react'

// Azure imports
import { useMsal } from '@azure/msal-react'
import { callSFTPapi } from '../../Azure/Config/azureFunctions'
import { loginRequest } from '../../Azure/Config/authConfig'

// scss stylesheet & icons
import './css/Streaming.scss'
import './css/StreamingMobile.scss'
import '../../Assets/Styles/Styles.scss'
import { IoCloseOutline } from "react-icons/io5";
import { AiOutlineArrowRight } from "react-icons/ai";


// Dropdown menu
import Select from 'react-select'
import DatePicker from 'react-date-picker/dist/entry.nostyle'
import '../../Assets/Styles/DatePicker/DatePicker.scss'
import '../../Assets/Styles/DatePicker/Calendar.scss'
import { streamingLocationOptions } from '../../Data/Data'

// components/pages import
import Cards from '../../Components/Cards/Cards'
import { TableContext } from '../../Context/TableContext'

// CSS Spinner & CSS Override for spinner
import ClipLoader from "react-spinners/ClipLoader";
const override = { 
    display: 'block',
    margin: '0 auto',
    borderColor: 'red',
};



const Streaming = (props) => {

    const { instance, accounts }                    = useMsal();
    let [ token , setToken ]                        = useState('');
    let [ user, setUser ]                           = useState('');
    let [ streamingLocation, setStreamingLocation]  = useState('default');
    let [ streamingOptions, setStreamingOptions ]   = useState([]);
    let [ SOLoading, setSOLoading]                  = useState(false)
    const [date, setDate]                           = useState(new Date()); 
    const [tableData, setTableData]                 = useState([]);
    const [tableLoading, setTableLoading]           = useState(false);

    // hook to set token when component mounts
    useEffect(() => {
        // get the access token from current session
        const request = {
            ...loginRequest,
            account: accounts[0]
        };
        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request).then((response) => {
            setToken(response.accessToken); // set the state of the token
        }).catch((e) => {
            console.log(e);
        });
    }, [])

    // hook to set parse token and set user when token changes
    useEffect(() => {
        // parse the token
        const parseToken = (token) => {
            const base64Url = token.split('.')[1];
            if (base64Url === undefined) {return {"name":"user"}}
            const base64 = base64Url.replace('-', '+').replace('_', '/');
            return JSON.parse(window.atob(base64));
        }
        // set the user
        if (token !== '') {setUser(parseToken(token).name);}
    }, [token])

    // hook to make get reqest to "http://localhost:7071/api/users" when user changes and get the list of cameras
    useEffect(() => {
        if (user !== '') {
            console.log('Getting list of cameras...');
            setSOLoading(true);
            // payload to send to the API
            const payload = {"username": user}
            // make get request to "http://localhost:7071/api/users" with the payload
            fetch('https://upcirclemediafunctions.azurewebsites.net/api/users', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            })
            .then(response => response.json())
            .then(data => {
                // get streaming locations list from the response
                const streamingLocations = data["cameras"].map((item) => {
                    return {
                        value: item["value"],
                        label: item["label"]
                    }
                }
                )
                setStreamingOptions(streamingLocations);
                setSOLoading(false);
            })
            .catch((error) => {
                console.error('Error in recieving cameras:', error);
            });
        }
    }, [user])

    // [DEBUG] function to console log the access token
    const handleTokenRequest = () => {console.log(token);}

    // Get media files from SFTP server
    const handleSFTPrequest = async () => {
        // check if date and streaming location are selected
        if (date === '' || streamingLocation === 'default') {
            alert('Please select a date and a streaming location');
            return;
        }

        // get day, month and year from Date() object and convert to string
        const year = date.getFullYear().toString();
        let month = (date.getMonth() + 1).toString();
        let day = date.getDate().toString();

        // append 0 to single digit numbers
        if (month.length === 1) {month = '0' + month;}
        if (day.length === 1) {day = '0' + day;}

        // set the state of the table loading
        setTableLoading(true);
        console.log('Loading table data...');
        try {
            const data = await callSFTPapi(token, streamingLocation, year, month, day); // make POST request to SFTP server
            setTableData(data); // set the state of the table data
        } catch (error) {
            setTableData([]); // set the state of the table data
        }   
        setTableLoading(false); // set the state of the table loading

        // set table data
        console.log('Table data loaded');
    }

    
    // style object for max width of the button
    const buttonStyle = {
        maxWidth: '200px',
        // center the button
        margin: 'auto',
        display: 'block',

    }

    return (
    <>

        <br />
        
        <h3 className='sectionTitle' style={{
            marginTop: '1rem',
            marginBottom: '1rem',
        }}>
            Welcome {user}! Select a streaming location and date to view the recorded videos:
        </h3>
        {/* coming soon */}
        <div className='subHeader'>
           
            
        </div>
        <br />
        
        {/*[DEBUGGING] Button to console log access token */}
        {/*
        <button className='button active' style={buttonStyle} onClick={ handleTokenRequest }>Get access token</button>
        */ }

        <h2 className='sectionTitle'>
            Recorded videos:
        </h2>
        {
            SOLoading ?
            <>
            <div className='sectionHeader'>
                <ClipLoader className='subHeader' color={'#123abc'} loading={SOLoading} css={override} size={150} />
            </div>
            <div className="sectionHeader">
                <div className='subHeader'>Loading...</div>
            </div>
            </>
            :
            <div className='sectionHeader'>
            
                <div className="subHeader">
                    <div className="subHeaderTitle">
                        Select a streaming location:
                    </div>
                    
                    <Select className='dropdownMenu' 
                    options={streamingOptions} 
                    onChange={e => setStreamingLocation(e.value)} />
                </div>

            

                <div className="subHeader">
                    <div className="subHeaderTitle">
                        Select the streaming date:
                    </div>
                    <DatePicker className="dateMenu  react-calendar react-date-picker " onChange={setDate} value={date}/>
                </div>

                <div className="subHeader">
                    <button className='button active' onClick={ () =>  handleSFTPrequest() }> 
                        <div className="text">Get Recorded Videos</div> 
                        <AiOutlineArrowRight className="icon" />
                    </button> 
                </div>
            </div>

        }
       

        
        {
            tableLoading ?
                <>
                <div className='sectionHeader'>
                    <ClipLoader className='subHeader' color={'#123abc'} loading={tableLoading} css={override} size={150} />
                </div>
                <div className="sectionHeader">
                    <div className='subHeader'>Loading...</div>
                </div>
                </>
                : <></>
        }
        
        {   // Display Table if data is not empty & table is not loading
            !tableLoading && tableData.length !== 0 ?
                <VideoManager tableData={tableData} />
                : <></>
        }
        {
            tableData.length === 0 && !tableLoading && !SOLoading ?
                <div className='sectionHeader'>
                    <div className='subHeader'>No data found.</div>
                </div>
                : <></>
        }

        {props.children}
    </>
    )
}


const VideoManager = (props) => {
    const [currRow, setCurrRow] = useState(52);
    return (
        <>
            {
                props.tableData.length === 0 ? 
                    <div>Loading</div> :
                    <TableContext.Provider value={{currRow, setCurrRow}}>
                    <Cards cardsData={[{
                                        title: 'Streamed videos',
                                        ctype: 'RecordedVideosTable'
                                    }]}

                            payload = {[props.tableData]}
                            sectionTitle = {'Select the video you want to download'}
                        
                    />
                    </TableContext.Provider>
            }
                
            {props.children}
        </>
    )
}

export const ModalPlayer = (props) => {
    
    const [modal, setModal]               = useState(false);
   
    
    // [AZURE BUG FIX] append timestamp to video URL to prevent caching
    let time = new Date();
    let videoURL = props.url + "&t=" + time.getTime();
    
    return(
        <>
        
        <button onClick={()=>setModal(true)} className="button active">
            <div className="text">play</div> 
        </button>

        {modal ? 
                <section className="modal__bg">
                    <div className="modal__align">
                    <IoCloseOutline
                            className="modal__close"
                            arial-label="Close modal"
                            onClick={() => setModal(false)}
                    />
                    <div className="modal__content">
                        <div className="modal__video-align">
                            <video
                            className="modal__video-style"
                            loading="lazy"
                            width="800"
                            height="500"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            controls
                            >
                                <source src={videoURL}/>
                            </video>        
                        </div>
                    </div>
                    </div>
                </section>
                
        : null}
        
            
        {props.children}
        </>

    )

}

export default Streaming

