import './css/Card.scss'

// components
import Infographic from "./Infographic";
import Table from "./Table";
import { RecordedVideosTable } from "./Table";
import CusMap from './CusMap';
import InfoCard from './InfoCard'

// icons & assets
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";


// temporary
import { obsData } from '../../Data/Data'



const Card = ({ cardData, payload }) => {
  return (
    // <div className="card">
    <>
        {
            cardData === null ? 'loading .. ':
            {
                'BasicCard': <BasicCard className='card' cardData={cardData} />,
                'Infographic': <Infographic  data={payload} />, // TODO : modify component Infographic to use prop name payload instead of data
                'InfoCard' : <InfoCard cardData={obsData} payload={null} />,
                'Table': <Table payload={payload} />,
                'RecordedVideosTable': <RecordedVideosTable payload={payload} />,
                'Map' : <CusMap payload={payload} />,
                'Empty' : <div className="card empty" style={{flex:2}}></div>
            }[cardData.ctype]
        }
    </>
  )
}

const BasicCard = ({cardData}) => {
    return(
        <div className="card basiccard">
            <div className="left">
                <span className="title">{cardData.title}</span>
                <span className="counter">
                    {cardData.count}
                </span>
            </div>
            <div className="right">
                <div className="percentage positive">
                <KeyboardArrowUpIcon />
                {cardData.percent}
                </div>
                <div className="iconWrapper">
                    {cardData.icon}
                </div>
            </div>
        </div>
        
    )
}



export default Card