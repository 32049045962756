// Description: This is the wrapper for all the pages that require authentication. It will check if the user is authenticated and if not it will redirect to the login page.


import { useEffect, useContext, useState } from "react";
import { userContext } from '../../Context/GlobalContexts'

// msal
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../Azure/Config/authConfig";

// Azure imports
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";

//components
import Sidebar from '../../Components/Sidebar/Sidebar'
import SidebarMobile from '../../Components/Sidebar/SidebarMobile'

// css
import './NavWrapper.scss'

// azure plugins
import Login from '../Login/Login';

const NavWrapper = (props) => {

    // get the current user
    const { user, setUser } = useContext(userContext);
    const { instance, accounts } = useMsal();
    const [token, setToken] = useState(null);

    useEffect(() => {
        if (accounts[0]) {
            instance.acquireTokenSilent({
                ...loginRequest,
                account: accounts[0]
            }).then((response) => {
                setToken(response.accessToken);
                setUser(accounts[0]);
            }).catch((e) => {
                console.error(e);
            });

        }
    }, [accounts, instance]);


  return (
        
        <>
        
        <div className="wrapper">
            
            <Sidebar value={props.value}/> 
            <SidebarMobile value={props.value}/>
           
            
            <div className="homeContainer">
                {/* <Navbar/> */}
                <UnauthenticatedTemplate>
                    <Login />
                </UnauthenticatedTemplate>
                <AuthenticatedTemplate>
                    {props.children}
                </AuthenticatedTemplate>
            </div>
        </div>
        </>
        
  )
}

export default NavWrapper