export const msalConfig = {
    auth: {
      clientId: "94baa90d-ecb3-400a-985b-aa787dce8a58",
      authority: "https://login.microsoftonline.com/common", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
      redirectUri: "https://dashboard.zeroplastics.ch/redirect",
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
  //  scopes: ["User.Read"] // this scope is using microsoft graph api to get users details eg. name, email etc 
    // scopes: ["https://management.azure.com/.default"], // this scope is giving access to the media servies api
    // get scope for azure key vault
    scopes: ["https://vault.azure.net/.default"],

  };
  
  // Add the endpoints here for Microsoft Graph API services you'd like to use.
  export const graphConfig = {
      graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
  };