import { createContext } from "react";

/*
provides global context information accross react components
*/ 

export const SidebarContext = createContext(null); // check which sidebar menu is being used

export const userContext = createContext(
    {
        user: null,
        setUser: () => {}
    }
); // check which user is logged in and their information