import './Insights.scss'
import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";

// components
import Cards from '../../Components/Cards/Cards'
import NavWrapper from '../NavWrapper/NavWrapper';
import { cardsData, midData, secondRowData } from "../../Data/Data";
import { observations } from "../../Data/observationData";
import FilteredObjectList from './FilteredObjectList';


// state context imports
import { TableContext } from '../../Context/TableContext';



const Insights = (props) => {

	// initialise the data for info cards
	// stats : itemCount, totalWeight, totalSold, perTypeCount
  	const [data, setData] = useState(null);
	const { instance } = useMsal();
 
	useEffect(() => {
		async function fetchData() {
			const initStats = {
				itemCount: 0,
				totalWeight: 0,
				totalSold: 0,
				perTypeCount: new Map()
			};
			setData(
				(await observations())
					//.filter((item) => props.owner === null || item.owner === props.owner)
					.reduce((stats, item) => {
						if (props.owner === null || item.owner === props.owner) {
							stats.itemCount += 1;
							stats.totalWeight += item.weight * 1000;
							stats.totalSold += item.owner !== null ? 1 : 0;
							const perTypeCount = stats.perTypeCount.get(item.object_type) ?? 0;
							stats.perTypeCount.set(item.object_type, perTypeCount + 1);
						}	
						return stats;
					}, initStats)
			);
		}
		void fetchData();
	}, [props.owner]);

	// fetch table data
	const [tableData, setTableData] = useState([]);
	const [originialTableData, setOriginalTableData] = useState([]);
	const [currRow, setCurrRow] = useState(3
	);
	useEffect(() => {
		async function fetchData() {
		  const data = await observations();
	  
		  const filteredAndSortedData = data.reduce((result, item) => {
			if (props.owner === null || item.owner === props.owner) {
			  const index = result.findIndex(
				(entry) => new Date(entry.observed_at) < new Date(item.observed_at)
			  );
			  if (index === -1) {
				result.push(item);
			  } else {
				result.splice(index, 0, item);
			  }
			}
			return result;
		  }, []);
		  setTableData(filteredAndSortedData);
		  setOriginalTableData(filteredAndSortedData);
		}
		void fetchData();
	  }, [props.owner]);

	  const myObjectList = [
		{ id: 1, name: "Apple", color: "Red", price: 0.5 },
		{ id: 2, name: "Banana", color: "Yellow", price: 0.2 },
		{ id: 3, name: "Orange", color: "Orange", price: 0.3 },
		{ id: 4, name: "Grapes", color: "Purple", price: 0.8 },
		{ id: 5, name: "Watermelon", color: "Green", price: 2.5 },
		{ id: 6, name: "Strawberry", color: "Red", price: 0.1 },
		{ id: 7, name: "Blueberry", color: "Blue", price: 0.15 },
		{ id: 8, name: "Pineapple", color: "Yellow", price: 1.5 },
		{ id: 9, name: "Mango", color: "Orange", price: 1.2 },
		{ id: 10, name: "Kiwi", color: "Brown", price: 0.4 }
	  ];
	  

	  const f = (data) => {
		// console.log(data);
	  }
  return (
	<>
	{
		data === null ? 'loading' :
		
			<NavWrapper value={0}>

				{/* GPT Filter */}
				

				{/* basic insights section */}
				<Cards cardsData={cardsData(data)} payload={null} sectionTitle={''}/>

		
				
				{/* table | infocard 1 |infocard 2 */}
				<TableContext.Provider value={{currRow, setCurrRow}}>
					<Cards cardsData={midData} payload={[tableData, null, null]} sectionTitle={'Waste Analysis'}/>
				</TableContext.Provider>

				
				{/* map | pie chart */}
				{/* <Cards cardsData={secondRowData} payload={[null, data]} sectionTitle={'Additional Insights'}/> */}

			</NavWrapper>
		
	}
	</>
  )
}

export default Insights