import Card from '../Card/Card'
import './Cards.scss'

const Cards = ( { cardsData , payload, sectionTitle} ) => {
  return (
    <>
    <h3 className="sectionTitle">
				{sectionTitle}
		</h3>
    <div className="cards">
        {
            cardsData.map((card,idx) => {
                return(
                    <Card className='card' 
                    key={idx}
                    cardData={card}
                    payload={ payload && payload[idx] }
                    />
                )
            })
        }
    </div>
    </>
  )
}

export default Cards