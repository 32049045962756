// Import React Components


// Icons Import
import BarChartIcon from '@mui/icons-material/BarChart';
import PersonIcon from '@mui/icons-material/Person';
import CategoryRoundedIcon from '@mui/icons-material/CategoryRounded';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';

// icons 
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'; // location
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'; // date
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded'; // type
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'; // accuracy
import FitnessCenterOutlinedIcon from '@mui/icons-material/FitnessCenterOutlined'; // weight
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'; // owner
import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined'; // detector
import DevicesIcon from '@mui/icons-material/Devices'; // device
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';

export const sidebarData = [
    {
        icon:<BarChartIcon className="icon"/>,
        text:'Dashboard',
        to:'/',
    },
    // {
    //     icon:<PersonIcon className="icon"/>,
    //     text:'My Impact',
    //     to:'/',
    // },
    {
      icon:<OndemandVideoIcon className="icon"/>,
      text:'Streaming',
      to:'/streaming',
  },
];


export const midDataOriginal = [
  {
    title: 'Chart',
    ctype: 'Table'
  },
  {
    title: 'Chart',
    ctype: 'Infographic'
  },
]
export const midData = [
  {
    title: 'Chart',
    ctype: 'Table'
  },
  {
    title: 'Info',
    ctype: 'InfoCard'
  },
]

export const secondRowData = [
  {
    title: 'Map',
    ctype: 'Map'
  },
  {
    title: 'Chart',
    ctype: 'Infographic'
  },
]


export const cardsData = (data) => {
  return(
    [
      {
          title: "Items Analyzed",
          count: data.itemCount,
          percent: "0%",
          icon: (
            <CategoryRoundedIcon
              className="icon"
              style={{
                color: "crimson",
                backgroundColor: "rgba(255, 0, 0, 0.2)",
              }}
            />
          ),
          ctype: "BasicCard"
        },
      {
          title: "Total Weight",
          count: `${(data.totalWeight / 1000).toFixed(1)} KG`,
          percent: "0%",
          icon: (
            <ChangeCircleOutlinedIcon
              className="icon"
              style={{
                  backgroundColor: "rgba(218, 165, 32, 0.2)",
                  color: "goldenrod",
              }}
            />
          ),
          ctype:"BasicCard"
        },
      {
          title: "Lost Revenue",
          count: `$ ${((data.totalWeight / 1000) * 0.5).toFixed(1)}`,
          percent: "0%",
          icon: (
            <PaidOutlinedIcon
              className="icon"
              style={{
                color: "#4eb607",
                backgroundColor: "rgba(91, 195, 112, 0.26)",
              }}
            />
          ),
          ctype:"BasicCard"
        },
    
    ]
  )  
} ;

export const obsData = [
  {
    icon: <LocationOnOutlinedIcon />,
    title: 'Location',
    tag: 'location_name'
  },
  {
    icon: <CalendarMonthIcon />,
    title: 'Date',
    tag: 'stored_at'
  },
  {
    icon: <SettingsRoundedIcon />,
    title: 'Type',
    tag: 'object_type'
  },
  {
    icon: <CheckCircleOutlineRoundedIcon />,
    title: 'Accuracy',
    tag: 'accuracy'
  },
  {
    icon: <FitnessCenterOutlinedIcon />,
    title: 'Weight',
    tag: 'weight'
  },
  {
    icon: <PersonOutlineOutlinedIcon />,
    title: 'Owner',
    owner: 'owner'
  },
  {
    icon: <EmojiObjectsOutlinedIcon />,
    title: 'Detector',
    tag: 'detector'
  },
  {
    icon: <DevicesIcon />,
    title: 'Device',
    tag: 'device'
  },
  // {
  //   icon: <MapIcon />,
  //   title: 'Coordinates',
  //   tag: 'device_location'
  // },
]

export const streamingLocationOptions = [ 
  { value: 'CH00001', label: 'Innorecycling' },
  { value: 'CH00004', label: 'Innoplastics' },
  { value: 'CH00005', label: 'Batrec' },
]	

	
 	
	