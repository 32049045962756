
let _observationPromise = null;

export async function observations() {
	if (_observationPromise === null) {
		_observationPromise = (await fetch(
			"/api/v0/observations"
		)).json();
	}
	return await _observationPromise;
}


