import { useEffect, useState } from "react";

// azure imports
import { useMsal } from "@azure/msal-react";
import { loginRequest } from '../../Azure/Config/authConfig'

// css scripts
import './Login.scss'
import '../../Assets/Styles/Styles.scss'

// icons
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'; // right arrow
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';  




const Login = () => {
    const { instance } = useMsal();

    const handleLogin = () =>
    {
        instance.loginRedirect(loginRequest)
        .catch(e => {console.error(e);});
    }

  return (
            <div className="loginContainer">
                <div className="loginCard">
                    <div className="displayMessage">
                        <OndemandVideoIcon fontSize='large' className='icon' />
                        {/* add text style center */}
                        <div className="" style={{textAlign:'center'}}>
                            You need to be Logged in.
                        </div>
                    </div>
                    
                    <button
                    className='button active'
                    onClick={() => handleLogin(instance)}>
                        <div className="text" style={{textAlign:'center'}}>
                            Continue to Login
                        </div>
                        <ArrowForwardIosIcon className='icon' />
                    </button> 
                    
                </div>
            </div>
        
  )
}

export default Login